import axios from "axios";
import useSound from "use-sound";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { DefaultRouteProps } from "@app/App";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import { User } from "@app/types/user";

import Context from "@app/contexts/Context";

import { getCharacterImages, getCharacterStatus } from "@app/utils/character";

import { GameImages } from "@app/assets/images/game/game";

import styles from "@app/assets/styles/gameStart.module.scss";

type SceneType = "genius" | "users";

export default function GameStart(props: DefaultRouteProps) {
    const { receiverContext } = props;

    const signHeight = Math.floor(((window.innerWidth * 0.5625) * 0.60) * 0.45);

    // Context
    const {
        ioSocket,
        roomId,
        setIoSocket,
        setRoomId
      } = useContext(Context);

    const navigate = useNavigate();
    const videoRef = useRef<HTMLVideoElement>(null);

    // Sound
    const soundManager = {
        question: useSound(require("../assets/sound/game/question.m4a")),
        polley: useSound(require("../assets/sound/game/polley.m4a")),
    };

    // State
    const [ scene, setScene ] = useState<SceneType>("genius");
    const [ players, setPlayers ] = useState<User[]>([]);
    const [ scrollValue, setScrollValue ] = useState<number>(signHeight);

    // Queries
    useQuery(["get", "players"], async () => {
            const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
            setPlayers(players);
        },
        { keepPreviousData: false }
    );


    let startValue = -(window.innerWidth / 2) + (((window.innerWidth / 12)) * (players.length - 1));
    if (startValue >= 0) {
        startValue = 1;
    }

    useEffect(() => {
        if (scene === "users") {
            setTimeout(() => {
                soundManager.question[0]();
                soundManager.polley[0]();
                setScrollValue(0);
                setTimeout(() => {
                    soundManager.question[1].stop();
                    soundManager.polley[1].stop();
                    navigate("/game");
                }, 5000);
            }, 1000);
        }
    }, [scene, players]);

    useEffect(() => {
        function userLogout(user: User) {
            if (user.leader) {
                if (ioSocket) {
                    ioSocket.emit("tvDisconnected");
                    ioSocket.disconnect();
                    setIoSocket(undefined);
                    setRoomId(undefined);
                    setPlayers([]);
                }

                if (receiverContext) {
                    receiverContext.stop();
                } else {
                    navigate("/");
                }
            }
        };

        function exitRoom() {
            setRoomId(undefined);
            if (ioSocket) {
                ioSocket.emit("tvDisconnected");
                ioSocket.disconnect();
            }
            setIoSocket(undefined);

            if (receiverContext) {
                receiverContext.stop();
            } else {
                navigate("/");
            }
        };

        function skip() {
            if (ioSocket) {
                ioSocket.off("skip", skip);
                ioSocket.off('userLogout', userLogout);
                ioSocket.off("deleteRoom", exitRoom);
            }
            navigate("/game");
        }

        if (ioSocket) {
            ioSocket.on("skip", skip);
            ioSocket.on('userLogout', userLogout);
            ioSocket.on("deleteRoom", exitRoom);
        }

        return () => {
            if (ioSocket) {
                ioSocket.off("skip", skip);
                ioSocket.off('userLogout', userLogout);
                ioSocket.off("deleteRoom", exitRoom);
            }
        };
    }, []);

    return (
        <div className={styles.gameStartContainer}>
            {scene === "genius" ? (
                <video
                    ref={videoRef}
                    src={require("../assets/video/intro_tv.mp4")}
                    autoPlay={true}
                    onPause={() => {
                        videoRef.current?.play();
                    }}
                    onEnded={() => {
                        setScene("users");
                    }}
                />
            ) : (
                <div
                    className={styles.wrapper}
                    style={{ background: `url(${GameImages.background[0]}) center / cover no-repeat` }}
                >
                    <div className={styles.charactersWrapper}>
                        <div className={styles.signsWrapper} style={{ top: -scrollValue }}>
                            {players.map((player, index) => {
                                return (
                                    <div
                                        className={styles.sign}
                                        style={{ background: `url(${GameImages.sign.url}) center / contain no-repeat` }}
                                    >
                                        <div>{player.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                        {players.map((player, index) => {
                            const characterImages = getCharacterImages(player.character);
                            const characterStatus = getCharacterStatus("answer", characterImages);
                            return (
                                <div key={player.id + index} className={styles.characterWrapper}>
                                    <img className={styles.characterImage} src={characterStatus} alt=""/>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{ flex: 2 }}/>
                </div>
            )}s
        </div>
    );
}